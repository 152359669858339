import React from 'react';
import * as Icon from 'react-feather';
import axios from 'axios';
import { UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { history } from 'history.js';

const UserDropdown = () => {
  return (
    <DropdownMenu right>
      <DropdownItem tag="a" href="#">
        <Icon.DollarSign size={14} className="mr-50" />
        <span className="align-middle">Appraise My Car</span>
      </DropdownItem>
      <DropdownItem divider />
      <DropdownItem tag="a" href="#" onClick={() => history.push('/pages/login')}>
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem>
    </DropdownMenu>
  );
};

class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    suggestions: [],
  };

  componentDidMount() {
    axios.get('/api/main-search/data').then(({ data }) => {
      this.setState({ suggestions: data.searchResult });
    });
  }

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch,
    });
  };

  render() {
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle
            tag="a"
            style={{ height: '100%' }}
            className="nav-link dropdown-user-link"
          >
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">{this.props.userName}</span>
              <span className="user-status">2016 Kia Sorento</span>
            </div>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul>
    );
  }
}

export default NavbarUser;
