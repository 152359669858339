import React, { Fragment } from 'react';
import { Edit, CheckSquare } from 'react-feather';
import { useDispatch } from 'react-redux';
import { useApiClientContext } from 'ApiClientProvider';
import { SCREENS, NOW_LOADING, END_LOADING } from 'app/SchedulerDashboard/utils/constants';
import { generateClassNameForContentPlacement } from 'app/SchedulerDashboard/utils/dashboardUtils';
import moment from 'moment';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { showErrorToast } from 'toast';
import { history } from 'history.js';
import { phoneNumber } from 'utility/format';

const ReviewPage = ({ currDataState, setActiveScreen }) => {
  const { schedulerApi } = useApiClientContext();

  const {
    services,
    appointmentInfo,
    customerInformation,
    transportInformation,
    vehicleInformation,
    otherRequest,
  } = currDataState;
  const APPT_INFO = [
    moment(appointmentInfo.currDate).format('ddd, MMMM Do'),
    moment(appointmentInfo.currSelectedTime.value).format('h:mm A'),
  ];
  const CUSTOMER_INFO = [
    customerInformation.customerName,
    `${phoneNumber(customerInformation.customerPhone)} • ${customerInformation.customerEmail}`,
  ];

  const CAR_INFO = [
    `${vehicleInformation.currYear} ${vehicleInformation.currMake}`,
    `${vehicleInformation.currModel}`,
  ];

  const renderInfos = [
    {
      header: { title: 'Individual Services', component: SCREENS.service },
      body: (
        <div>
          {services?.map((service, idx) => (
            <Fragment key={idx}>
              <Row className="my-50">
                <Col className={generateClassNameForContentPlacement('start')}>
                  <p className="m-0 mr-1 font-weight-lighter">
                    {service.appointmentCode.title} - {service.appointmentCode.serviceLane}
                  </p>
                  <CheckSquare color="#28C76F" size={24} />
                </Col>
              </Row>
              <Row className="my-50">
                <Col>
                  <p className="m-0 mr-1 font-weight-lighter">{service.customerNotes}</p>
                </Col>
              </Row>
            </Fragment>
          ))}
        </div>
      ),
      custom: true,
    },
    {
      header: { title: 'Other Requests', component: SCREENS.service },
      body: (
        <Row className="my-50">
          <Col className={generateClassNameForContentPlacement('start')}>
            <p className="m-0 mr-1 font-weight-lighter">{otherRequest}</p>
            <CheckSquare color="#28C76F" size={24} />
          </Col>
        </Row>
      ),
      custom: true,
    },
    {
      header: { title: 'Appointment', component: SCREENS.calenderScheduler },
      body: APPT_INFO,
      custom: false,
    },
    {
      header: { title: 'Customer Information', component: SCREENS.customerInformation },
      body: CUSTOMER_INFO,
    },
    {
      header: { title: 'Vehicle Information', component: SCREENS.vehicleInformation },
      body: CAR_INFO,
      custom: false,
    },
  ];
  const dispatch = useDispatch();

  const handleSubmitAppointment = async () => {
    const params = {
      customerId: customerInformation.customer.id,
      appointmentServices: JSON.stringify(
        services.map((service) => ({
          code: service.appointmentCode.code,
          type: 'appointmentCode',
          customerNotes: service.customerNotes, // don't think either of these notes are used, probably copy paste junk
          internalNotes: service.internalNotes,
        })),
      ),
      alternativeTransportationTypeId: transportInformation?.selectedTransportationType?.id,
      date: appointmentInfo.currSelectedTime.value,
      textUpdates: customerInformation.phone !== '',
      emailUpdates: customerInformation.email !== '',
      vehicleYear: vehicleInformation.currYear,
      vehicleMake: vehicleInformation.currMake,
      vehicleModel: vehicleInformation.currModel,
      otherRequest: otherRequest,
    };
    dispatch({ type: NOW_LOADING });
    try {
      await schedulerApi.post('/appointment', params);
      const scheduleParams = new URLSearchParams({
        data: Buffer.from(JSON.stringify(currDataState)).toString('base64'),
      });

      history.push({
        pathname: '/confirm-success',
        search: scheduleParams.toString(),
      });
    } catch (error) {
      showErrorToast({ message: error.response.data.message });
    } finally {
      dispatch({ type: END_LOADING });
    }
  };

  const renderHeaderSection = (title, screenName) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        columnGap: '1rem',
      }}
    >
      <h2 style={{ fontSize: 21, width: 'fit-content', margin: 0 }}>{title}</h2>
      <div style={{ borderBottom: '1px solid #0D47A1', flex: 1, minWidth: '1rem' }} />
      <Button
        className="px-1 px-sm-1 d-flex align-items-center"
        outline
        color="primary"
        onClick={() => setActiveScreen(screenName)}
      >
        <Edit size={14} />
        <p className="m-0 pl-0 pl-sm-1">Edit</p>
      </Button>
    </div>
  );

  const renderRowInfoSection = (copies) => {
    return copies.map((item, idx) => (
      <Row key={idx} className={idx === copies.length - 1 ? 'mb-2' : ''}>
        <Col>
          <div style={{ fontWeight: 300 }}>{item}</div>
        </Col>
      </Row>
    ));
  };

  return (
    <Fragment>
      <Card>
        <CardBody>
          <Container>
            <Row>
              <Col className={generateClassNameForContentPlacement('center')}>
                <h1>Review</h1>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col xs="9"></Col>
            </Row>
            {renderInfos.map(({ header, body, custom }, key) => (
              <Card key={key}>
                <CardBody>
                  <Container>
                    {renderHeaderSection(header.title, header.component)}
                    {custom ? body : renderRowInfoSection(body)}
                  </Container>
                </CardBody>
              </Card>
            ))}
            <Card>
              <CardBody>
                <Container>
                  {renderHeaderSection('Transportation Information', SCREENS.carSelection)}
                  <Row>
                    <Col>
                      <div style={{ fontWeight: 300 }}>
                        {transportInformation?.selectedTransportationType?.title}
                      </div>
                    </Col>
                  </Row>
                </Container>
              </CardBody>
            </Card>
          </Container>
        </CardBody>
        <Container>
          <Row>
            <Col className={generateClassNameForContentPlacement('center')}>
              <Button color="success" onClick={handleSubmitAppointment}>
                Confirm Appointment
              </Button>
            </Col>
          </Row>
        </Container>
      </Card>
    </Fragment>
  );
};

export default ReviewPage;
