import React from 'react';
import { ArrowUp } from 'react-feather';
import ScrollToTop from 'react-scroll-up';
import classnames from 'classnames';
import { Button } from 'reactstrap';

const Footer = (props) => {
  let footerTypeArr = ['sticky', 'static', 'hidden'];
  return (
    <footer
      className={classnames('footer footer-light', {
        'footer-static': props.footerType === 'static' || !footerTypeArr.includes(props.footerType),
        'd-none': props.footerType === 'hidden',
      })}
    >
      <p className="mb-0 clearfix text-dark font-weight-normal">
        <span className="d-block d-md-inline-block text-black-50">
          Copyright © {new Date().getFullYear()}
          <a
            href="https://iserviceauto.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="font-weight-bold"
          >
            <u>iService Auto,</u>
          </a>
          All rights reserved
        </span>
      </p>
      {props.hideScrollToTop === false && (
        <ScrollToTop showUnder={160}>
          <Button
            color="primary"
            className="scroll-top"
            onClick={() => {
              if (props.isMpiVisible) props.showMpi(false);
              if (props.isQuoteVisible) props.showQuote(false);
            }}
          >
            {props.isMpiVisible ? (
              'Close Report'
            ) : props.isQuoteVisible ? (
              'Go Back'
            ) : (
              <ArrowUp size={24} />
            )}
          </Button>
        </ScrollToTop>
      )}
    </footer>
  );
};

export default Footer;
