import clientApi from 'clientApi';
import { handleError } from 'redux/actions/dashboard/index';

// GET Demo by ROID
export const getDemo = () => async (dispatch, getState) => {
  const roId = getState().dashboard.repairOrder?.id;

  dispatch({ type: 'GET_DEMO' });

  try {
    const { data } = await clientApi.getDemo(roId);

    dispatch({ type: 'RECEIVE_DEMO', data });
    return data;
  } catch (err) {
    dispatch(handleError(err));
    return false;
  }
};
