import clientApi from 'clientApi';
import { handleError } from 'redux/actions/dashboard/index';

export const getCoupons = () => async (dispatch) => {
  dispatch({ type: 'GET_COUPONS' });

  try {
    const { data } = await clientApi.getCoupons();

    dispatch({ type: 'RECEIVE_COUPONS', data: data?.results });
  } catch (err) {
    dispatch(handleError(err));
  }
};
export const logCouponRequest = (repairOrderId) => async (dispatch) => {
  try {
    await clientApi.logCouponRequest(repairOrderId);
  } catch (error) {
    dispatch(handleError(error));
  }
};
