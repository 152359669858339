import CalendarScheduler from 'app/SchedulerDashboard/CalendarScheduler';
import CarSelection from 'app/SchedulerDashboard/CarSelection';
import CustomerInformation from 'app/SchedulerDashboard/CustomerInformation';
import ReviewPage from 'app/SchedulerDashboard/ReviewPage';
import Service from 'app/SchedulerDashboard/ServiceSelection';
import VehicleInformation from 'app/SchedulerDashboard/VehicleInformation';

export const SCREENS = {
  vehicleInformation: {
    name: 'vehicleInformation',
    component: VehicleInformation,
  },
  service: {
    name: 'service',
    component: Service,
  },
  calenderScheduler: {
    name: 'calenderScheduler',
    component: CalendarScheduler,
  },
  customerInformation: {
    name: 'customerInformation',
    component: CustomerInformation,
  },
  carSelection: {
    name: 'carSelection',
    component: CarSelection,
  },
  review: {
    name: 'review',
    component: ReviewPage,
  },
};

export const TIME_SECTION_ENUM = {
  MORNING: 'MORNING',
  AFTERNOON: 'AFTERNOON',
  EVENING: 'EVENING',
};

export const PREV = 'prev';
export const NEXT = 'next';
export const CLIENT_TOKEN = 'iservice_client_app_token';
export const NOW_LOADING = 'NOW_LOADING';
export const END_LOADING = 'END_LOADING';
