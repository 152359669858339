import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useSwipeable } from 'react-swipeable';
import classnames from 'classnames';

import SidebarHeader from 'layouts/components/menu/vertical-menu/SidebarHeader';
import SideMenuContent from 'layouts/components/menu/vertical-menu/sidemenu/SideMenuContent';
import { ContextLayout } from 'utility/context/Layout';

const Sidebar = (props) => {
  const { currentUser } = useSelector((state) => state.auth.login);

  const [activeIndex, setActiveIndex] = useState(null);
  const [hoveredMenuItem, setHoveredMenuItem] = useState(null);
  const [activeItem, setActiveItem] = useState(props.activePath);
  const [menuShadow] = useState(false);

  const swipedRightHandlers = useSwipeable({
    onSwipedRight: () => props.sidebarVisibility(),
  });

  const swipedLeftHandlers = useSwipeable({
    onSwipedRight: () => props.sidebarVisibility(),
  });

  // Change active index
  const changeActiveIndex = (id) => {
    setActiveIndex(id !== activeIndex ? id : null);
  };

  // Handle mouse enter
  const handleMouseEnterMenuItem = (id) => {
    setHoveredMenuItem(id !== hoveredMenuItem ? id : null);
  };

  // Handle active item
  const handleActiveItem = (url) => {
    setActiveItem(url);
  };

  return (
    <ContextLayout.Consumer>
      {(context) => {
        const dir = context.state.direction;

        return (
          <React.Fragment>
            <div
              className="menu-swipe-area d-xl-none d-block vh-100"
              {...(dir === 'rtl' ? swipedLeftHandlers : swipedRightHandlers)}
            ></div>

            <div
              className={classnames(
                `main-menu menu-fixed menu-light menu-accordion menu-shadow theme-${props.activeTheme}`,
                {
                  collapsed: props.sidebarState === true,
                  'hide-sidebar': props.visibilityState === false && props.deviceWidth < 1200,
                },
              )}
              onMouseEnter={() => props.sidebarHover(false)}
              onMouseLeave={() => props.sidebarHover(true)}
            >
              <SidebarHeader {...props} menuShadow={menuShadow} activeItem={activeItem} />
              <ul
                className="navigation navigation-main"
                {...(dir === 'rtl' ? swipedRightHandlers : swipedLeftHandlers)}
              >
                <SideMenuContent
                  activeIndex={activeIndex}
                  changeActiveIndex={changeActiveIndex}
                  hoveredMenuItem={hoveredMenuItem}
                  handleSidebarMouseEnter={handleMouseEnterMenuItem}
                  activeItem={activeItem}
                  handleActiveItem={handleActiveItem}
                  currentUser={currentUser}
                  {...props}
                />
              </ul>
            </div>
          </React.Fragment>
        );
      }}
    </ContextLayout.Consumer>
  );
};

export default Sidebar;
