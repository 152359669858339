import React, { useRef, useState, useEffect, Fragment, useCallback } from 'react';
import Calendar from 'react-calendar';
import { ChevronLeft, ChevronRight } from 'react-feather';
import ReactQuill from 'react-quill';
import { useDispatch, useSelector } from 'react-redux';
import { useApiClientContext } from 'ApiClientProvider';
import {
  SCREENS,
  PREV,
  NEXT,
  NOW_LOADING,
  END_LOADING,
} from 'app/SchedulerDashboard/utils/constants';
import { TIME_SECTION_ENUM } from 'app/SchedulerDashboard/utils/constants';
import { generateClassNameForContentPlacement } from 'app/SchedulerDashboard/utils/dashboardUtils';
import { setTimesForRender } from 'app/SchedulerDashboard/utils/dashboardUtils';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { Button, Card, CardBody, Container, Row, Col, Alert } from 'reactstrap';
import 'app/SchedulerDashboard/style/Calendar.scss';
import { showErrorToast } from 'toast';

const FORWARD_IDX = 1;
const BACKWARD_IDX = -1;
const YEAR_FORMAT = 'YYYY-MM-DD';
const INITIAL_MORNING_IDX = { startIdx: 0, endIdx: 5 };
const INITIAL_AFTERNOON_IDX = { startIdx: 0, endIdx: 5 };

const CalendarScheduler = ({ setActiveScreen, currDataState, setCurrDataState }) => {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.dashboard.settings);
  const { schedulerApi } = useApiClientContext();
  const { schedulerAvailabilityOverrideStatement } = settings ?? {};
  const appointmentRef = useRef(true);
  const { appointmentInfo, services } = currDataState;
  const [value, setValue] = useState(
    appointmentInfo.currDate ? new Date(appointmentInfo.currDate + 'T00:00:01') : new Date(),
  );
  const [currDate, setCurrDate] = useState(moment(value).format(YEAR_FORMAT));
  const [morningTimes, setMorningTimes] = useState([]);
  const [afternoonTimes, setAfternoonTimes] = useState([]);
  const [slotsInCalendar, setSlotsInCalendar] = useState([]);
  const [morningIdx, setMorningIdx] = useState(appointmentInfo.morningIdx ?? INITIAL_MORNING_IDX);
  const [afternoonIdx, setAfternoonIdx] = useState(
    appointmentInfo.afternoonIdx ?? INITIAL_AFTERNOON_IDX,
  );
  const [currSelectedTime, setCurrSelectedTime] = useState({});
  const [isMobile, setIsMobile] = useState(false);

  const codes = JSON.stringify(
    services.map(({ appointmentCode: { code } }) => ({ code, type: 'appointmentCode' })),
  );
  const datesInCalendar = slotsInCalendar.map((slot) => moment(slot).format(YEAR_FORMAT));

  const onCalendarChange = async (value, slots) => {
    const date = moment(value).format(YEAR_FORMAT);
    const availableSlots = slots.filter((dateInMonth) => moment(dateInMonth).isSame(date, 'day'));
    if (availableSlots.length) {
      setValue(value);
    } else {
      setValue(new Date(moment(value).add(1, 'day')));
    }

    const getMorningTimes = await setTimesForRender(
      date,
      availableSlots || [],
      TIME_SECTION_ENUM.MORNING,
    );
    const getAfternoonTimes = await setTimesForRender(
      date,
      availableSlots || [],
      TIME_SECTION_ENUM.AFTERNOON,
    );
    setMorningTimes(getMorningTimes || []);
    setAfternoonTimes(getAfternoonTimes || []);
  };

  const updateSlots = useCallback(
    async (date) => {
      try {
        const slots = [];
        dispatch({ type: NOW_LOADING });
        const res = await schedulerApi.get(
          `/appointment-availability?dateMonth=${moment(date).format(YEAR_FORMAT)}&codes=${codes}`,
        );
        const dataInMonth = res.data;
        const allSlotsInMonth = dataInMonth?.results || [];
        const resNextMonth = await schedulerApi.get(
          `/appointment-availability?dateMonth=${moment(date)
            .add(1, 'month')
            .endOf('month')
            .format(YEAR_FORMAT)}&codes=${codes}`,
        );
        const dataInNextMonth = resNextMonth.data;
        const allSlotsInNextMonth = dataInNextMonth?.results || [];
        [...allSlotsInMonth, ...allSlotsInNextMonth].forEach((slot) => {
          if (!!slot.available && !slots.includes(slot.timeSlot)) {
            slots.push(slot.timeSlot);
          }
        });
        onCalendarChange(new Date(date + 'T00:00:01'), slots);
        setSlotsInCalendar(slots);
      } catch (error) {
        showErrorToast(error.response?.data ?? error);
      } finally {
        dispatch({ type: END_LOADING });
      }
    },
    [codes, dispatch],
  );

  // Trigger on first mount
  useEffect(() => {
    (async () => {
      await updateSlots(currDate);
    })();
  }, []);

  // Current Date Time Slots
  useEffect(() => {
    const date = moment(value).format(YEAR_FORMAT);
    const availableSlots = slotsInCalendar.filter((dateInMonth) =>
      moment(dateInMonth).isSame(date, 'day'),
    );
    (async () => {
      const getMorningTimes = await setTimesForRender(
        date,
        availableSlots || [],
        TIME_SECTION_ENUM.MORNING,
      );
      const getAfternoonTimes = await setTimesForRender(
        date,
        availableSlots || [],
        TIME_SECTION_ENUM.AFTERNOON,
      );
      setMorningTimes(getMorningTimes || []);
      setAfternoonTimes(getAfternoonTimes || []);
    })();
  }, [slotsInCalendar, value]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth < 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    });
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  useEffect(() => {
    let selectedTime;
    if (appointmentRef.current) {
      /** run on load to check existing data */
      selectedTime = appointmentInfo.currSelectedTime ?? {};
      appointmentRef.current = false;
    } else {
      /** run on every action taken after loading */
      selectedTime = {};
      setMorningIdx(INITIAL_MORNING_IDX);
      setAfternoonIdx(INITIAL_AFTERNOON_IDX);
    }
    setCurrSelectedTime(selectedTime);
    setCurrDate(moment(value).format(YEAR_FORMAT));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleWindowChange = (window) => {
    const currDataStateDeepCopy = JSON.parse(JSON.stringify(currDataState));
    currDataStateDeepCopy.appointmentInfo = {
      currDate,
      currSelectedTime,
      morningIdx,
      afternoonIdx,
    };
    setCurrDataState(currDataStateDeepCopy);
    if (window === NEXT) {
      setActiveScreen(SCREENS.carSelection);
    } else if (window === PREV) {
      setActiveScreen(SCREENS.service);
    }
  };

  const adjustIndexWindow = (
    operator,
    availableRangeForTimeOfDay,
    timeOfDayIdx,
    setTimeOfDayIdx,
  ) => {
    if (timeOfDayIdx.startIdx === 0 && operator === BACKWARD_IDX) return;
    if (timeOfDayIdx.endIdx === availableRangeForTimeOfDay.length + 1 && operator === FORWARD_IDX)
      return;
    setTimeOfDayIdx({
      startIdx: timeOfDayIdx.startIdx + operator,
      endIdx: timeOfDayIdx.endIdx + operator,
    });
  };

  const generateClassNameForTimeSlotRow = (placement) => {
    return classnames(generateClassNameForContentPlacement(placement), 'mb-1');
  };

  const generateTimeSlots = (
    availableRangeForTimeOfDay,
    timeOfDayIdx,
    setTimeOfDayIdx,
    timeSectionLabel,
  ) => {
    return (
      <Container className="p-0 mb-4">
        <Row>
          <Col className={generateClassNameForTimeSlotRow('center')}>
            <h5>{timeSectionLabel}</h5>
          </Col>
        </Row>
        <Row className="flex-nowrap">
          {availableRangeForTimeOfDay.length > 0 && (
            <Col className={generateClassNameForContentPlacement('start')}>
              {timeOfDayIdx.startIdx !== 0 && (
                <ChevronLeft
                  onClick={() =>
                    adjustIndexWindow(
                      BACKWARD_IDX,
                      availableRangeForTimeOfDay,
                      timeOfDayIdx,
                      setTimeOfDayIdx,
                    )
                  }
                />
              )}
            </Col>
          )}
          {availableRangeForTimeOfDay
            .slice(
              timeOfDayIdx.startIdx,
              isMobile ? timeOfDayIdx.startIdx + 4 : timeOfDayIdx.endIdx,
            )
            .map((time) => (
              <Button
                key={time.label}
                color={time.value === currSelectedTime.value ? 'success' : 'primary'}
                onClick={() => {
                  setCurrSelectedTime(time);
                }}
                className="px-1 px-sm-2 ml-1 ml-sm-2"
              >
                {time.label}
              </Button>
            ))}
          {availableRangeForTimeOfDay.length > 0 && (
            <Col className={generateClassNameForContentPlacement('end')}>
              {timeOfDayIdx.endIdx < availableRangeForTimeOfDay.length && (
                <ChevronRight
                  onClick={() =>
                    adjustIndexWindow(
                      FORWARD_IDX,
                      availableRangeForTimeOfDay,
                      timeOfDayIdx,
                      setTimeOfDayIdx,
                    )
                  }
                />
              )}
            </Col>
          )}
        </Row>
      </Container>
    );
  };

  const fetchMonthAvailability = async (month) => {
    await updateSlots(month);
  };

  const onActiveStartDateChange = ({ activeStartDate }) => {
    const startDate = moment(activeStartDate).isSameOrAfter(moment())
      ? activeStartDate
      : moment().toDate();

    return fetchMonthAvailability(moment(startDate).format(YEAR_FORMAT));
  };

  return (
    <Fragment>
      <Card>
        <CardBody>
          <Container>
            <Col className="text-center pb-2">
              <h1>What's a good time for you?</h1>
            </Col>
            <Col>
              <ReactQuill
                className="w-100 text-center"
                value={schedulerAvailabilityOverrideStatement}
                readOnly
                theme="bubble"
                modules={{ toolbar: null }}
              />
            </Col>
            <Row>
              <Col className={generateClassNameForContentPlacement('center')}>
                <div className="shadow-sm">
                  <Calendar
                    prev2Label={null}
                    next2Label={null}
                    minDate={new Date()}
                    minDetail="year"
                    calendarType="US"
                    onChange={(selectedDate) => onCalendarChange(selectedDate, slotsInCalendar)}
                    onActiveStartDateChange={onActiveStartDateChange}
                    value={value}
                    tileDisabled={({ date }) => {
                      return !datesInCalendar.includes(moment(date).format(YEAR_FORMAT));
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </CardBody>
      </Card>

      {morningTimes.length > 0 || afternoonTimes.length > 0 ? (
        <Card className="w-100">
          {generateTimeSlots(morningTimes, morningIdx, setMorningIdx, 'Morning Time Slots (AM)')}
          {generateTimeSlots(
            afternoonTimes,
            afternoonIdx,
            setAfternoonIdx,
            'Afternoon Time Slots (PM)',
          )}
        </Card>
      ) : (
        <Card>
          <CardBody>
            <Container>
              <Row>
                <Col className={generateClassNameForContentPlacement('center') + ' w-100'}>
                  No Available Times
                </Col>
              </Row>
            </Container>
          </CardBody>
        </Card>
      )}
      <Alert
        color="success"
        className="text-center w-50 self-center mx-auto"
        style={{
          fontWeight: '600',
          fontSize: '21px',
          lineHeight: '26px',
        }}
      >
        {`${moment(value).format('ddd, MMMM Do')} ${
          currSelectedTime?.value ? moment(currSelectedTime.value).format(' h:mm A') : ''
        }`}
      </Alert>
      <Card>
        <CardBody>
          <Container>
            <Row>
              <Col className={generateClassNameForContentPlacement('start')}>
                <Button
                  className="nav-btn px-2"
                  outline
                  color="primary"
                  onClick={() => handleWindowChange(PREV)}
                >
                  <ChevronLeft size={16} /> Back
                </Button>
              </Col>
              <Col className={generateClassNameForContentPlacement('end')}>
                <Button
                  className="nav-btn next px-2"
                  disabled={isEmpty(currSelectedTime)}
                  color="success"
                  onClick={() => handleWindowChange(NEXT)}
                >
                  <span
                    style={{
                      paddingRight: '10px',
                    }}
                  >
                    Next
                  </span>
                  <ChevronRight size={16} />
                </Button>
              </Col>
            </Row>
          </Container>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default CalendarScheduler;
