import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { ChevronRight, ChevronLeft } from 'react-feather';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { useApiClientContext } from 'ApiClientProvider';
import { SCREENS, NOW_LOADING, END_LOADING } from 'app/SchedulerDashboard/utils/constants';
import { PREV, NEXT } from 'app/SchedulerDashboard/utils/constants';
import { generateClassNameForContentPlacement } from 'app/SchedulerDashboard/utils/dashboardUtils';
import cloneDeep from 'lodash/cloneDeep';
import { Button, Col, Container, Row, FormGroup, Label, Input } from 'reactstrap';
import { showErrorToast } from 'toast';
import RedAsterisk from 'components/custom/RedAsterisk';

const VehicleInformation = ({ setActiveScreen, currDataState, setCurrDataState }) => {
  const dispatch = useDispatch();
  const { vehicleInformation, customerInformation } = currDataState;
  const [currYear, setCurrYear] = useState(vehicleInformation.currYear ?? null);
  const [currMake, setCurrMake] = useState(vehicleInformation.currMake ?? null);
  const [currModel, setCurrModel] = useState(vehicleInformation.currModel ?? null);
  const [yearSelection, setYearSelection] = useState([]);
  const [makeSelection, setMakeSelection] = useState([]);
  const [modelSelection, setModelSelection] = useState([]);
  const { schedulerApi } = useApiClientContext();

  const { settings } = useSelector((state) => state.dashboard);

  const customerVehicles = customerInformation.customer?.customerVehicles;

  const isMotorcycleIndustry = settings?.industry === 'motorcycle';

  const vehicleOptions = useMemo(() => {
    if (!customerVehicles?.length) return [];
    return customerVehicles.map((vehicle) => ({
      label: `${vehicle.year} ${vehicle.make} ${vehicle.model}`,
      value: vehicle,
    }));
  }, [customerVehicles]);

  useEffect(() => {
    if (!isMotorcycleIndustry) {
      handleKBBCalls('/kbb/kbb-year', setYearSelection, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMotorcycleIndustry]);

  useEffect(() => {
    if (currYear !== null && !isMotorcycleIndustry) {
      handleKBBCalls(`/kbb/kbb-make?year=${currYear}`, setMakeSelection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currYear, isMotorcycleIndustry]);

  useEffect(() => {
    if (currMake !== null && !isMotorcycleIndustry) {
      handleKBBCalls(`/kbb/kbb-model?make=${currMake}&year=${currYear}`, setModelSelection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currMake, isMotorcycleIndustry]);

  const handleKBBCalls = async (url, setHookFunc, reverse = false) => {
    dispatch({ type: NOW_LOADING });
    try {
      const response = await schedulerApi.get(url);
      setHookFunc(reverse ? response.data.slice().reverse() : response.data);
    } catch (err) {
      showErrorToast(err);
    } finally {
      dispatch({ type: END_LOADING });
    }
  };

  const handleWindowChange = (window) => {
    const currStateDeepCopy = cloneDeep(currDataState);
    currStateDeepCopy.vehicleInformation = {
      currYear,
      currMake,
      currModel,
    };
    setCurrDataState(currStateDeepCopy);
    if (window === PREV) {
      setActiveScreen(SCREENS.customerInformation);
    } else if (window === NEXT) {
      setActiveScreen(SCREENS.review);
    }
  };

  const handleSelectVehicle = (option) => {
    if (option) {
      const currStateDeepCopy = cloneDeep(currDataState);
      currStateDeepCopy.selectedVehicle = option;
      setCurrDataState(currStateDeepCopy);
      setCurrYear(option.value.year);
      setCurrMake(option.value.make);
      setCurrModel(option.value.model);
    } else {
      const currStateDeepCopy = cloneDeep(currDataState);
      currStateDeepCopy.selectedVehicle = undefined;
      setCurrDataState(currStateDeepCopy);
      setCurrYear(null);
      setCurrMake(null);
      setCurrModel(null);
    }
  };

  return (
    <Fragment>
      <Container className="pb-4">
        <Col className="text-center">
          <h1 className="pb-1">Enter your vehicle information</h1>
          <h5 className="font-weight-light">We service all makes and models.</h5>
        </Col>
        {!!customerVehicles?.length && (
          <>
            <Row className="mt-4">
              <Col className="text-center">
                <h3>Find Vehicle</h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label className="h6">Customer Vehicles</Label>
                  <Select
                    placeholder="Select"
                    isSearchable={true}
                    options={vehicleOptions}
                    onChange={handleSelectVehicle}
                    value={currDataState.selectedVehicle}
                    isClearable
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col className="text-center">
                <h3>Or Add New Vehicle</h3>
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col>
            <FormGroup>
              <Label className="h6">
                Year <RedAsterisk />
              </Label>
              {isMotorcycleIndustry ? (
                <Input
                  type="number"
                  value={currYear}
                  onChange={({ target }) => setCurrYear(target.value)}
                />
              ) : (
                <Select
                  placeholder="Select"
                  value={
                    !currDataState.selectedVehicle &&
                    yearSelection.find((item) => item.year === currYear)
                  }
                  isSearchable={true}
                  options={yearSelection}
                  getOptionLabel={(item) => item.year}
                  getOptionValue={(item) => item.year}
                  onChange={(item) => {
                    setCurrYear(item.year);
                    setCurrMake(null);
                    setCurrModel(null);
                  }}
                  isDisabled={!!currDataState.selectedVehicle}
                />
              )}
            </FormGroup>
            {(currYear || isMotorcycleIndustry) && !currDataState.selectedVehicle && (
              <FormGroup>
                <Label className="h6">
                  Make <RedAsterisk />
                </Label>
                {isMotorcycleIndustry ? (
                  <Input value={currMake} onChange={({ target }) => setCurrMake(target.value)} />
                ) : (
                  <Select
                    placeholder="Select"
                    value={currMake ? makeSelection.find((item) => item.make === currMake) : ''}
                    isSearchable={true}
                    options={makeSelection}
                    getOptionLabel={(item) => item.make}
                    getOptionValue={(item) => item.make}
                    onChange={(item) => {
                      setCurrMake(item.make);
                      setCurrModel(null);
                    }}
                    isDisabled={!!currDataState.selectedVehicle}
                  />
                )}
              </FormGroup>
            )}
            {(currMake || isMotorcycleIndustry) && !currDataState.selectedVehicle && (
              <FormGroup>
                <Label className="h6">
                  Model <RedAsterisk />
                </Label>
                {isMotorcycleIndustry ? (
                  <Input value={currModel} onChange={({ target }) => setCurrModel(target.value)} />
                ) : (
                  <Select
                    placeholder="Select"
                    value={currModel ? modelSelection.find((item) => item.model === currModel) : ''}
                    isSearchable={true}
                    options={modelSelection}
                    getOptionLabel={(item) => item.model}
                    getOptionValue={(item) => item.model}
                    onChange={(item) => {
                      setCurrModel(item.model);
                    }}
                    isDisabled={!!currDataState.selectedVehicle}
                  />
                )}
              </FormGroup>
            )}
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col className={generateClassNameForContentPlacement('start')}>
            <Button
              className="nav-btn px-2"
              outline
              color="primary"
              onClick={() => handleWindowChange(PREV)}
            >
              <ChevronLeft size={16} /> Back
            </Button>
          </Col>
          <Col className={generateClassNameForContentPlacement('end')}>
            <Button
              className="nav-btn next px-2"
              disabled={!currMake || !currModel || !currYear}
              color="success"
              onClick={() => handleWindowChange(NEXT)}
            >
              <span
                style={{
                  paddingRight: '10px',
                }}
              >
                Next
              </span>
              <ChevronRight size={16} />
            </Button>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default VehicleInformation;
