import clientApi from 'clientApi';
import { handleError } from 'redux/actions/dashboard/index';

export const getRewardsTiers = () => async (dispatch) => {
  dispatch({ type: 'GET_REWARDS_TIERS' });

  try {
    const { data } = await clientApi.getRewardsTiers();

    dispatch({ type: 'RECEIVE_REWARDS_TIERS', data: data?.results });
  } catch (err) {
    dispatch(handleError(err));
  }
};
