import React from 'react';
import { RiCloseLine } from 'react-icons/ri';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  Input,
  InputGroupAddon,
  Button,
} from 'reactstrap';

const ServiceNoteModal = ({
  isOpen = false,
  service = {},
  toggle = () => {},
  onChange = () => {},
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <p className="text-center">Note</p>
      </ModalHeader>
      <ModalBody>
        <InputGroup>
          <Input
            type="textarea"
            rows={5}
            placeholder="Add notes (optional)"
            value={service?.customerNotes || ''}
            onChange={(e) => onChange(e.target.value)}
            className="border-0"
          />
          {service?.customerNotes && (
            <InputGroupAddon addonType="append">
              <Button
                color="white"
                className="d-flex align-items-center m-0"
                onClick={() => onChange('')}
              >
                <RiCloseLine size={16} />
              </Button>
            </InputGroupAddon>
          )}
        </InputGroup>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-center">
        <Button color="success" onClick={toggle}>
          Done
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ServiceNoteModal;
