import React, { useEffect, useState, createContext, useContext } from 'react';
import clientApi from 'clientApi';
import schedulerApi from 'schedulerApi';

export const ApiClientContext = createContext();

export default function ApiClientProvider({ children }) {
  const [apiConfig, setApiConfig] = useState(null);
  const [hasLoadedConfig, setHasLoadedConfig] = useState(false);

  useEffect(() => {
    async function fetchApiConfig() {
      try {
        const response = await fetch(`${process.env.PUBLIC_URL}/config.json`);
        const json = await response.json();

        setApiConfig(json);
        setHasLoadedConfig(true);
      } catch (e) {
        setHasLoadedConfig(true);
      }
    }

    fetchApiConfig();
  }, []);

  useEffect(() => {
    if (hasLoadedConfig && apiConfig && apiConfig.apiUrl) {
      clientApi._updateBaseUrl(apiConfig.apiUrl);
      schedulerApi.defaults.baseURL = apiConfig.apiUrl;
    }
  }, [hasLoadedConfig, apiConfig]);

  return (
    <ApiClientContext.Provider
      value={{
        clientApi,
        schedulerApi,
      }}
    >
      {hasLoadedConfig && children}
    </ApiClientContext.Provider>
  );
}

export const useApiClientContext = () => {
  const context = useContext(ApiClientContext);

  if (!context) {
    throw new Error('useApiClientContext must be used within ApiClientProvider');
  }

  return context;
};
