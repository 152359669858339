const initialState = {
  isLoading: false,
};

const schedulerReducer = (state = initialState, action) => {
  const { data } = action;
  switch (action.type) {
    case 'LOADING_START':
      return { ...state, isLoading: true };
    case 'LOADING_END':
      return { ...state, isLoading: false };
    case 'RECEIVE_APPOINTMENT_GROUP':
      return { ...state, appointmentGroup: data, isLoading: false };
    default:
      return { ...state };
  }
};

export default schedulerReducer;
