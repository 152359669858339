export const toBool = (val) => {
  return val === '1' || val === 'true' || val === true;
};

export function phoneNumber(unformatted) {
  if (!unformatted) return '';
  const cleaned = unformatted.toString().replace(/\D/g, '');

  return cleaned.length === 7
    ? cleaned.replace(/(\d{3})(\d{4})/, '$1-$2')
    : cleaned.length === 10
    ? cleaned.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
    : '';
}

export function concatFirstAndLastNames(first, last) {
  const concatenated = `${first ?? ''} ${last ?? ''}`;
  return concatenated.trim();
}

export function parseDate(date) {
  const dateObject = new Date(date);
  return dateObject.toString() === 'Invalid Date'
    ? new Date(date && date.replace && date.replace(' ', 'T'))
    : dateObject;
}

export function datetime(date) {
  return parseDate(date).toLocaleString();
}

export function date(date) {
  return parseDate(date).toLocaleDateString();
}

export function time(date) {
  return parseDate(date).toLocaleTimeString();
}

export function money(
  amount,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
  showCurrencySymbol = true,
) {
  return typeof amount === 'boolean' ||
    typeof amount === 'undefined' ||
    typeof amount === 'object' ||
    Number(amount).toString() === 'NaN' ||
    Math.abs(amount) === Infinity ||
    amount === ''
    ? ''
    : Number(amount).toLocaleString('en-US', {
        currency: 'USD',
        minimumFractionDigits, // passing in 0 for min and max removes decimal
        maximumFractionDigits,
        style: showCurrencySymbol ? 'currency' : 'decimal',
      });
}

export function vehicleInfo(year, make, model) {
  return `${year || ''}${year ? ' ' : ''}${make || ''}${make ? ' ' : ''}${model || ''}`;
}
