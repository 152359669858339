import { CLIENT_TOKEN } from 'app/SchedulerDashboard/utils/constants';
import axios from 'axios';
import { getSubdomain } from 'clientApi';
import qs from 'qs';

const schedulerApi = axios.create({ baseURL: `https://${getSubdomain()}.iserviceauto.com/api` });

schedulerApi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(CLIENT_TOKEN);
    if (token) config.headers.Authorization = `Bearer ${token}`;

    if (config.method === 'post') {
      config.transformRequest = (data) => {
        const formData = new FormData();
        for (const key in data) {
          formData.append(key, data[key]);
        }
        return formData;
      };
    }

    if (config.method === 'put') {
      config.transformRequest = (data) => qs.stringify(data);
    }

    if (config.method === 'patch') {
      config.transformRequest = (data) => qs.stringify(data);
    }

    if (config.method === 'get') {
      config.paramsSerializer = (params) => qs.stringify(params);
    }

    return config;
  },
  (response) => response,
);

export default schedulerApi;
