import clientApi from 'clientApi';
import { resetDashboardState, startPollingRO } from 'redux/actions/dashboard';

export const login = (linkHash, isPayment) => {
  return async (dispatch) => {
    dispatch(resetAuthState());
    dispatch(resetDashboardState());
    dispatch({ type: 'LOGIN' });

    try {
      const { data } = await clientApi.login(linkHash, isPayment);
      const token = data?.token;

      if (token) {
        return dispatch(receiveToken(token, linkHash, isPayment));
      }
    } catch (err) {
      dispatch(receiveUnauthorized());
    }
  };
};

export const receiveUnauthorized = (error) => {
  // Removing the cookie takes away the client route at the Router level, so it won't end up in an infinite authentication loop
  localStorage.removeItem('iservice_customer_app_token');
  return (dispatch) => {
    // dispatch(resetAuthState());
    // dispatch(resetDashboardState());

    return dispatch({ type: 'RECEIVE_UNAUTHORIZED', error });
  };
};

export const receiveToken = (token, linkHash, isPayment) => {
  localStorage.setItem('iservice_customer_app_token', token);

  return (dispatch) => {
    dispatch({ type: 'RECEIVE_TOKEN', linkHash });
    if (!isPayment) dispatch(startPollingRO());
  };
};

export const resetAuthState = () => (dispatch) => {
  dispatch({ type: 'RESET_AUTH_STATE' });
};
