import clientApi, { handleETagError } from 'clientApi';
import { getRO } from 'redux/actions/dashboard/index';

// Toggle Cards
export const toggleMpi = (mpiId) => async (dispatch, getState) => {
  dispatch({ type: 'TOGGLE_MPI' });

  if (getState().dashboard.viewedMpi) return;

  try {
    await clientApi.viewedMpi(mpiId);

    dispatch(getRO());
  } catch (err) {
    // Fail silently to the user
    console.error(err);
  }
};
export const toggleUVEye = () => async (dispatch) => {
  dispatch({ type: 'TOGGLE_UVEye' });
};
export const toggleQuote = (quote) => async (dispatch, getState) => {
  const viewedQuote = !!getState().dashboard.viewedQuote;

  dispatch({ type: 'TOGGLE_QUOTE' });
  if (viewedQuote) return;

  try {
    const response = await clientApi.viewedQuote(quote.id, quote.eTag);
    dispatch({ type: 'RECEIVE_QUOTE', data: response.data });
  } catch (err) {
    // Fail silently to the user
    dispatch(handleETagError(err));
    console.error(err);
  }
};
export const toggleReview = (repairOrderReview) => async (dispatch) => {
  dispatch({ type: 'TOGGLE_REVIEW' });

  if (repairOrderReview.dateViewed) return;

  try {
    await clientApi.viewedReview(repairOrderReview.id);
  } catch (err) {
    // Fail silently to the user
    console.error(err);
  }
};
export const toggleVideo = (video) => async (dispatch) => {
  dispatch({ type: 'TOGGLE_VIDEO', videoId: video.id });
};
export const toggleWaiver = () => async (dispatch, getState) => {
  dispatch({ type: 'TOGGLE_WAIVER' });

  if (getState().dashboard.waiverSignature) return;

  try {
    await clientApi.viewedWaiver(getState().dashboard.repairOrder?.id);
  } catch (err) {
    // Fail silently to the user
    console.error(err);
  }
};
export const videoViewed = (video) => async (dispatch) => {
  if (video.status === 'Viewed') return;

  try {
    await clientApi.viewedVideo(video.id);
    dispatch({ type: 'VIDEO_VIEWED', video });
  } catch (err) {
    // Fail silently to the user
    console.error(err);
  }
};
