import clientApi from 'clientApi';
import { showErrorToast } from 'toast';
import { handleError, nowLoading } from 'redux/actions/dashboard/index';

export const createMyLoan = (repairOrder) => async (dispatch) => {
  dispatch(nowLoading());

  try {
    const { data } = await clientApi.createMyLoan({ repairOrderId: repairOrder?.id });
    dispatch({ type: 'END_LOADING' });

    return data;
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const requestMyLoanApplication =
  (
    id, // myLoanId
    terms,
    amount,
  ) =>
  async (dispatch) => {
    dispatch(nowLoading());

    try {
      const { data } = await clientApi.requestMyLoanApplication({
        id,
        terms,
        amount,
      });
      dispatch({ type: 'END_LOADING' });
      return data;
    } catch (err) {
      if (err?.response?.status === 400 || err?.response?.status === 404) {
        showErrorToast({ message: 'User not found' });
      }
    }
  };
