import clientApi from 'clientApi';
import { financeRequested, handleError, nowLoading } from 'redux/actions/dashboard/index';

export const logFinanceRequest = (repairOrderId) => async (dispatch) => {
  try {
    await clientApi.logFinanceRequest(repairOrderId);
  } catch (err) {
    dispatch(handleError(err));
  }
};
export const requestFinance = () => async (dispatch) => {
  dispatch(nowLoading());

  try {
    await clientApi.financeRequest();

    dispatch(financeRequested());
  } catch (err) {
    dispatch(handleError(err));
  }
};
